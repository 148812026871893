import * as React from "react";
import { graphql } from "gatsby";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import SingleArticleSection from "../components/sections/SingleArticleSection";

const SingleArticle = ({ data }) => {
  const { body } = data.mdx;
  const { title, embeddedImagesLocal } = data.mdx.frontmatter;

  return (
    <div className="bg-white">
      <Header />
      <SingleArticleSection
        title={title}
        body={body}
        localImages={embeddedImagesLocal}
      />
      <Footer />
    </div>
  );
};

export default SingleArticle;

export const query = graphql`
  query Article($url: String) {
    mdx(frontmatter: { url: { eq: $url } }) {
      body
      frontmatter {
        url
        title
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
