import * as React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Title from "../elements/Title";
import { Link } from "gatsby";
import PageTitle from "../elements/PageTitle";

// Styled elements
const Paragraph = (props) => <p className="mb-4" {...props} />;
const H2 = (props) => (
  <Paragraph>
    <h2
      className="block text-2xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl"
      {...props}
    />
  </Paragraph>
);
const H3 = (props) => (
  <Paragraph>
    <h3
      className="block text-2xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl"
      {...props}
    />
  </Paragraph>
);
// Styled components
const TableHeadItem = (props) => (
  <th scope="col" class="py-3 px-6" {...props} />
);
const Table = ({ headerItems, rowItems }) => (
  <Paragraph>
    <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table class="w-full text-base text-left text-gray-500 dark:text-gray-400">
        <thead class="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {headerItems.map((item) => (
              <TableHeadItem>{item}</TableHeadItem>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowItems.map((row, ind) => (
            <tr key={ind} className={ind % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              {row.map((str) => (
                <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">
                  {str}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Paragraph>
);
const List = ({ listItems, IsNested, children }) => (
  <Paragraph className={`${IsNested ? "ml-8 mb-4" : "mb-4"}`}>
    <ul className="list-inside list-disc text-gray-600">
      {listItems.map((str) => (
        <li>{str}</li>
      ))}
    </ul>
    {children}
  </Paragraph>
);

const ArticleImg = ({ children, src, position, size }) => {
  let cl = "h-full w-full";

  switch (size) {
    case "small":
      cl = "h-auto min-w-[25%]";
      break;
    case "medium":
      cl = "h-auto min-w-[50%]";
      break;
    case "big":
      cl = "h-full w-full";
      break;
    default:
      cl = "h-full w-full";
      break;
  }

  const isRightPos = position === "right";
  const isBigSize = size === "big";

  return (
    <Paragraph
      className={`mb-4 gap-5 ${isBigSize ? "inline-block" : "inline-flex"} ${
        isRightPos && "flex-row-reverse"
      }`}
    >
      <GatsbyImage
        image={getImage(src)}
        className={`h-full w-full ${cl} object-contain`}
        alt=""
        objectFit="contain"
      />
      {children && (
        <Paragraph className={`inline-block ${isBigSize && "mt-5"}`}>
          {children}
        </Paragraph>
      )}
    </Paragraph>
  );
};
const ArticleLink = ({ path, IsNested, children }) => (
  <Link
    to={path}
    className="inline-block text-amber-600 font-bold hover:underline"
  >
    {children}
  </Link>
);

const styledElements = {
  h2: H2,
  h3: H3,
  p: Paragraph,
};
const styledComponents = {
  ArticleImg,
  ArticleLink,
  Table,
  List,
};
export const components = { ...styledElements, ...styledComponents };

const SingleArticleSection = ({ title, body, localImages }) => (
  <section className="bg-white">
    <PageTitle pageTitle={title} />
    {/* Header */}
    <Title title={title} />

    <div className="relative bg-white mt-12 overflow-hidden">
      {/* Decorate pattern */}
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            ></rect>
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            ></rect>
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            ></rect>
          </svg>
        </div>
      </div>
      {/* Content */}
      <div className="relative px-8 sm:px-12 lg:px-64 xl:px-80">
        <div className="text-lg prose prose-violet prose-lg text-gray-500 mx-auto">
          <MDXProvider components={components}>
            <MDXRenderer localImages={localImages}>
              {/* Content of article */}
              {body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </div>
  </section>
);

export default SingleArticleSection;
